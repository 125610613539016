import React from 'react';
import logo from './images/logo-4.png'
import Register from './Register'
import $ from 'jquery'
import './style.css'

export default function Header(props) {
    const scrollinicio = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    const scrollsobre = () => {
        window.scrollTo({
            top: 700,
            behavior: 'smooth'
        })
    }

    const handleClick = (options) => {
        switch (options) {
            case 'inicio':
                scrollinicio()
                props.setDisp({
                    visible: "",
                    user: "",
                    senha: "",
                    ip: '',
                    token: props.token
                })
                break;
            case 'sobre':
                scrollsobre();
                props.setDisp({
                    visible: "",
                    user: "",
                    senha: "",
                    ip: '',
                    token: props.token
                })
                break;
            case 'register':
                props.setDisp({
                    visible: "flex",
                    user: "",
                    senha: "",
                    ip: '',
                    token: props.token
                })
                break
        
            default:
                break;
        }
    }


    const ActMenu = () => {
        $('body').toggleClass('menuExpanded')
        $('.navList').toggleClass('active')
    }

    return (
        <>
            <ion-icon 
                style = {{display: 'none'}} 
                name = 'menu-outline' 
                class = 'menuOpen' 
                onClick={ActMenu}>
            </ion-icon>
            <header
                id='header'>
                <div
                    className='header-elements'
                    >
                    <a
                        draggable='false'
                        href='https://discord.gg/MjSbYW5gBn'
                        target='_blank'>
                        <img
                            src={logo}
                            alt='Logo'
                            draggable='false' />
                    </a>
                    <ul className='navList' style = {{display : 'flex'}}>
                        <div
                            className='headerMenu'
                            style={{ display: 'none' }}>
                            <a
                                draggable='false'
                                href='https://discord.gg/MjSbYW5gBn'
                                target='_blank'>
                                <img
                                    src={logo}
                                    alt='Logo'
                                    draggable='false' />
                            </a>
                            <ion-icon
                                name='close-outline'
                                className='menuClose'
                                onClick={ActMenu}
                                style = {{color : 'white', fontSize: '2rem', cursor : 'pointer'}}>
                            </ion-icon>
                        </div>
                        <li>
                            <a
                                href='#'
                                style={{ fontSize: '2vh' }}
                                onClick={() => handleClick('inicio')}>
                                INICIO
                            </a>
                        </li>
                        <li>
                            <a
                                href='https://discord.gg/MjSbYW5gBn'
                                target='_blank'
                                style={{ fontSize: '2vh' }}>
                                CONTATO </a>
                        </li>
                        <li>
                            <a
                                href='#'
                                style={{ fontSize: '2vh' }}
                                onClick={() => handleClick('sobre')}>
                                SOBRE
                            </a>
                        </li>
                        <li>
                            <a
                                href='#'
                                style={{ fontSize: '2vh' }}
                                onClick={() => handleClick('register')}
                                className = 'registerA'
                            >
                                REGISTER
                            </a>
                        </li>
                        <a
                            href='https://discord.gg/MjSbYW5gBn'
                            className='button'
                            style={{ display: 'none' }}>
                            Entre no nosso Discord
                        </a>
                        <ul
                            className='social-links'
                            style={{ display: 'none' }}>
                            <li>
                                <a
                                    href='https://github.com/gtndeveloper'
                                    draggable='false'
                                    target='_blank'
                                    style={{ color: 'white', border: '0px' + 'solid' + 'transparent' }}>
                                    <ion-icon
                                        style={{ fontSize: '1.7rem' }}
                                        name='logo-github'
                                        draggable='false'>
                                    </ion-icon>
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://discord.gg/MjSbYW5gBn'
                                    draggable='false'
                                    target='_blank'
                                    style={{ color: 'white', border: '0px' + 'solid' + 'transparent' }}>
                                    <ion-icon
                                        style={{ fontSize: '1.7rem' }}
                                        name='logo-discord'
                                        draggable='false'>
                                    </ion-icon>
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.behance.net/gtndev'
                                    draggable='false'
                                    target='_blank'
                                    style={{ color: 'white', border: '0px' + 'solid' + 'transparent' }}>
                                    <ion-icon
                                        style={{ fontSize: '1.7rem' }}
                                        name='logo-behance'
                                        draggable='false'>
                                    </ion-icon>
                                </a>
                            </li>
                        </ul>
                    </ul>
                </div>
                <nav>
                    <ul>
                        <li>
                            <a
                                href='https://github.com/gtndeveloper'
                                draggable='false'
                                target='_blank'
                                style={{ color: 'black', borderBottom: '0px solid transparent' }}>
                                <ion-icon
                                    name='logo-github'
                                    draggable='false'>
                                </ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href='https://discord.gg/MjSbYW5gBn'
                                draggable='false'
                                target='_blank'
                                style={{ color: 'black', borderBottom: '0px solid transparent' }}>
                                <ion-icon
                                    name='logo-discord'
                                    draggable='false'>
                                </ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href='https://www.behance.net/gtndev'
                                draggable='false'
                                target='_blank'
                                style={{ color: 'black', borderBottom: '0px solid transparent' }}>
                                <ion-icon
                                    name='logo-behance'
                                    draggable='false'>
                                </ion-icon>
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    )
    
}