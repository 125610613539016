export default {
    enviar: async () => {
        const response = await fetch("http://localhost:8080/ger/4", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify()
        })
        const data = await response.json();
        return data.token
    }
}