import React, { useEffect } from 'react';
import { useState } from 'react';
import Header from './components/Header'
import logo from './components/images/logo-4.png'
import $ from 'jquery'
import Api from './Api'
import Get from './components/get'
import Random from './components/random'
import Register from './components/Register'
import './components/style.css'

export default function App() {
  const [disp, setdisp] = useState({
    visible: '',
    user: '',
    senha: '',
    ip : '',
    token: ''
  })

  const handlerSend = async (e) => {
    let name = e.nome
    let senha = e.senha
    let ip = e.ip
    let token = e.token    

    let result = await (Api.enviar(name, senha, ip, token))
    if (result.tokenResult == 'logado') {
      console.log(result)
      localStorage.setItem('47jsdxcjvahsdjjjjxcghawngtzxbaksda', result.token)
      if (result.token == localStorage.getItem('47jsdxcjvahsdjjjjxcghawngtzxbaksda')) {
        console.log('oi')
        var options = document.querySelector('.registerA')
        options.style.display = 'none'
        window.location.reload();
      }
    }
  }

  const handleValue = (e,action) => {
      const teste = async () => {
        setdisp({
          visible: disp.visible,
          user: disp.user,
          senha: disp.senha,
          ip: disp.ip,
          token: await Random.enviar()
        })
        let result = await (Get.retornado(disp.token))
      }
      teste()
    setdisp({
      visible: disp.visible,
      user: disp.user,
      senha: disp.senha,
      ip: disp.ip,
      token: disp.token
    });
    if (e.visible == 'flex') {
      $('main').css('display', 'none')
      $('body').addClass('register')
      $('#main-register').css('display','flex')
    } else {
      $('main').css('display', 'none')
      $('body').removeClass('register')
    }
    if (action != undefined) {
      if (action == 'send') {
        $.getJSON("https://api.ipify.org/?format=jsonp&callback=?",function (ipV) {
          handlerSend({
            nome: e.user,
            senha: e.senha,
            ip: ipV.ip,
            token: disp.token
          })
        })
      }
    }
  }


  const Auth = () => {
    var key = localStorage.getItem('47jsdxcjvahsdjjjjxcghawngtzxbaksda')
    if (key == '_84jasdjxcl__asjxcyuawhbcnAsd' || key == '_4sdjx1cl_21_asjxcyuawhbcnAsd' || key == null || key == undefined) {
      localStorage.setItem('47jsdxcjvahsdjjjjxcghawngtzxbaksda', '_4sdjx1cl_21_asjxcyuawhbcnAsd')
      handleValue({
        visible: "flex",
        user: "",
        senha: "",
        token: disp.token
      })
    }
  }

  const Ready = async () => {
    $('img,ul,li,a').attr('draggable', 'false')
  }

  setTimeout(() => {
    Auth()
  }, 2000);

  return (
    <>
    <Header disp={disp} setDisp={handleValue} token={disp.token}/>
    <Register setDisp={handleValue} token={disp.token}/>
    <main id = 'main-about' className = 'rowInitial' onLoad={Ready}>
      <div className = 'elements-top'>
          <img src = {logo} alt = 'Logo' />
          <p>Murilo Mendes</p>
      </div>
    </main>
    <main id = 'main-about' className = 'row'>
        <div className = 'elmn'>
            <div id = 'elm'>
                <span>
                    Hello i'm,
                    <p>Murilo Mendes.</p>
                    <h1>
                        Developer
                        Front End<b style = {{color: '#424242'}}>.</b>
                    </h1>
                </span>
                <p>Como assim? <br />
                    O Front-end está muito relacionado com a interface gráfica do projeto.<br />
                    Ou seja, é onde se desenvolve a aplicação com a qual o usuário irá interagir<br /> diretamente, seja em softwares,
                    sites, aplicativos, etc.<br />
                    Que tal da uma olhada nos nossos produtos?
                </p>
            </div>
            <img src = {logo} alt = 'Logo' />
        </div>
    </main>  
    <main id = 'main-about' className = 'row row2'>
      <div className = 'elmn'>
        <img src = {logo} alt = 'Logo' />
          <div id = 'elm'>
            <span>
              <h1>
                Minhas Skills<b style={{ color: '#424242' }}>.</b><br />
              </h1>
              <p> JAVASCRIPT </p>
              <p> HTML </p>
              <p> CSS </p>
              <p>JQUERY</p>
            </span>
          </div>
      </div>
    </main>  
    <main id = 'main-about' className = 'row row3'>
      <div className = 'elmn'>
        <img src = {logo} alt = 'Logo' />
          <div id = 'elm'>
            <span>
              <h2>
                Entre no nosso discord<br />
              </h2>
              <a href = 'https://discord.gg/MjSbYW5gBn' target = '_blank'> 
                <button style = {{color: 'var(--PrimaryColor)'}}>Clique aqui!</button>
              </a>
            </span>
          </div>
      </div>
    </main>  
    </> 
  )
}
