export default {
    retornado: async(token) => {
        const response = await fetch("http://localhost:8080/getToken/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify()
        })
        const data = await response.json();

        // data.dbValue.forEach(element => {
        //     console.log(element)
        // });

        return data.dbValue;
    }
}

