export default {
    enviar: async(nome, senha, ipV,token) => {
        const response = await fetch("http://localhost:8080/colect/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
                nome, senha, ip: ipV
            })
        })
        const data = await response.json();
        if (data.dbValue != undefined) {
            data.dbValue.forEach(function (element, i) {
                if (element.nome == nome || element.token == token) {
                    data.ok = false
                    return data.ok = false ? "Esse usuário ja está cadastrado" : false
                }
            })
            var length = data.dbValue.length = data.dbValue.length += 1
        }

        function logFalse() {
            localStorage.setItem('47jsdxcjvahsdjjjjxcghawngtzxbaksda', '_4sdjx1cl_21_asjxcyuawhbcnAsd')
        }
        
        if (data.ok == true) {
            const Login = await fetch("http://localhost:8080/login/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({
                    nome, senha, id: length, ip: ipV, token: token
                })
            })
            const LoginResult = await Login.json()
            var lgtValue = LoginResult.dbValue.length

            const tokenResult = LoginResult.dbValue[lgtValue = lgtValue -= 1].token
            for (let i = 0; i < LoginResult.dbValue.length; i++) {
                const result = LoginResult.dbValue[i].token
                if (result == token) {
                    data.resultAuth = 'logado';
                    data.token = token
                } else {
                    logFalse()
                    data.token = 'logout'
                }
            }

            let resultLogin = data.ok == true ? "Voce foi registrado com sucesso" : false
            return {
                tokenResult: data.resultAuth,
                token: data.token,
                loginResult: resultLogin
            };
        } else if (data.ok === "Dados Invalidos") {
            logFalse()
            return "Dados Invalidos";
        } else {
            logFalse()
            return "Esse usuário ja está cadastrado";
        }
    }
}

