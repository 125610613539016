import React, { Component } from 'react';
import Logo from './images/logo-4.png'
import $ from 'jquery'

export default class Register extends Component {

    send(e) {        
        this.props.setDisp({
            visible: 'flex',
            user: $(e.target).parent().find('.Email').val(),
            senha: $(e.target).parent().find('.Senha').val(),
            ip: '',
            token: this.props.token
        },'send')
    }

    render() {
        return (
            <>
                <main id = 'main-register' className = 'row5' style={{display : this.props.visibleLogin === undefined ? 'none' : this.props.visibleLogin}}>
                    <div className = 'Regst'>
                        <div className = 'left'>
                            <img src  =  {Logo} />
                        </div>
                        <div className = 'right'>
                            <div className = 'mainRight'>
                                <div className = 'infoRight'>
                                    <label> Ola! </label>
                                    <p>Inscreva-se para <wbr /> começar</p>
                                </div>
                                <input 
                                    className = 'Email'
                                    type = 'text' 
                                    placeholder = 'Email' 
                                    spellCheck = 'false' />
                                <input 
                                    className='Senha'
                                    type = 'text' 
                                    placeholder = 'Senha' 
                                    spellCheck='false' />
                                <button onClick={(e) => this.send(e)}>
                                    ENVIAR
                                </button>
                            </div>
                        </div>
                    </div>
                </main>  
            </>
        )
    }
}